












































































































































import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";
import { KeepAlive } from "@/models/entities/keep-alive.interface";
import { Note } from "@/models/entities/note.interface";
import { CRadio, CRadioStatus } from "@/models/utils/c-radio.interface";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppBarTab } from "@/models/utils/c-tab.interface";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { equipment } from "@/services/api/equipment.service";
import TabModule from "@/store/modules/tab.module";
import { Employee } from "@/models/entities/employee.interface";
import { Actions } from "@/models/entities/action.interface";
import { CalendarJobById } from "@/models/entities/calendar-job-byId.interface";
import { Equipment } from "@/models/entities/equipment.interface";
import OverlayModule from "@/store/modules/overlay.module";
import { calendarJob } from "@/services/api/calendarJob.service";
import { CalendarJobGeo } from "@/models/entities/Calendar-job-geo.interface";
import SnackbarModule from "@/store/modules/snackbar.module";
const snackbarModule = getModule(SnackbarModule, store);
const tabModule = getModule(TabModule, store);
const overlayModule = getModule(OverlayModule, store);

const CMap = () => import(/* webpackChunkName: "CMap" */ "./Map/CMap.vue");
const CEquipments = () =>
  import(/* webpackChunkName: "CEquipments" */ "./Equipments/CEquipments.vue");
const CNotesArea = () =>
  import(/* webpackChunkName: "CNotesArea" */ "./Notes/CNotesArea.vue");

@Component({
  components: {
    "c-map": CMap,
    "c-equipments": CEquipments,
    "c-notes-area": CNotesArea,
  },
})
export default class CEmployeeInfo extends Vue {
  @Prop({ required: false, default: null, type: [Object, String] })
  private job: CalendarJobById;
  @Prop({ required: false, default: null, type: Array })
  private keepAlives: KeepAlive[];
  @Prop({ required: false, default: null, type: Array })
  private notes: Note[];
  @Prop({ required: false, default: null, type: Array })
  private employeesForReplacement: Employee[];

  private radiosSliderModel = 0;

  private dialog = {
    show: false,
    type: null,
    map: null as CalendarJobGeo,
    equipmentAnswers: {} as { [id: string]: Equipment[] },
  };
  private initialSelectedStatus = null;

  get tab(): AppBarTab {
    return tabModule.current;
  }

  get radios(): Partial<CRadio>[] {
    let r: Partial<CRadio>[] = [];
    let times: string[] = [];
    if (this.keepAlives.length) {
      for (const el of this.keepAlives) {
        if (el.class === "success") {
          times.push(el.keepAlive);
          r.push({
            time: format(new Date(el.keepAlive), "HH:mm"),
            status: CRadioStatus.CHECK,
          });
        } else if (el.class === "error") {
          times.push(el.expected);
          r.push({
            time: format(new Date(el.expected), "HH:mm"),
            status: CRadioStatus.ERROR,
          });
        } else {
          times.push(null);
          r.push({ status: CRadioStatus.NONE });
        }
      }
    } else {
      for (let i = 0; i < 7; i++) {
        r.push({ status: CRadioStatus.NONE });
      }
    }
    this.radiosSliderModel = this.getRadiosSliderModel(r.length - 1, times);
    return r;
  }

  private created(): void {
    this.initialSelectedStatus = this.job.lastActionId;
  }

  private getDate(date: string): string {
    return date ? format(new Date(date), "dd/MM/yyyy") : "--";
  }

  private getTime(start: string, end: string): string {
    const s = start && start.trim() !== "" ? this.formatTime(start) : "--:--";
    const e = end && end.trim() !== "" ? this.formatTime(end) : "--:--";
    return s + " - " + e;
  }

  private formatTime(time: string): string {
    return format(new Date(time), "HH:mm");
  }

  private getChipIcon(type: string): string {
    return CONDITIONS_ICON(type);
  }

  private getChipType(type: string): string {
    return CONDITIONS_TYPE(type);
  }

  private onChangeStatus(lastActionId: number): void {
    // lastActionId == 4 => va scelto anche sostituto prima di salvare
    if (lastActionId !== Actions.SUBSTITUTES) {
      let job = Object.assign({}, this.job);
      if (lastActionId === Actions.GPG_ONPOINT) {
        this.$nextTick(() => {
          this.$set(this.job, "lastActionId", this.initialSelectedStatus);
          this.$emit("open-gpg-dialog", job);
        });
      } else {
        this.initialSelectedStatus = lastActionId;
        this.$emit("update-status", job);
      }
    }
  }

  private async loadMapData(): Promise<void> {
    this.$set(this.dialog, "type", "map");
    overlayModule.showOverlay();
    let r = await calendarJob.getCoordinatesCalendarJobId(
      this.job.calendarJobId
    );
    this.$set(this.dialog, "map", r.data);
    this.$set(this.dialog, "show", true);
  }

  private async saveLatlng(calendarJobId, officeId, request): Promise<void> {
    overlayModule.showOverlay();
    this.$set(this.dialog, "show", false);
    const r = await calendarJob.saveOfficeCoordinates(
      calendarJobId,
      officeId,
      request
    );
    this.$set(this.dialog, "map", r.data);
    this.$set(this.dialog, "show", true);
    snackbarModule.showSnackbar({
      message: `Coordinate salvate con successo`,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }

  private async loadEquipmentsAnswers(): Promise<void> {
    this.$set(this.dialog, "type", "equipments");
    overlayModule.showOverlay();
    let r = await equipment.getEquipmentsAnswersByCalendarJobId(
      this.job.calendarJobId
    );
    const answersDict = r.data.reduce((res, value) => {
      (res[value.equipmentName] = res[value.equipmentName] || []).push(value);
      return res;
    }, {});
    this.$set(this.dialog, "equipmentAnswers", answersDict);
    this.$set(this.dialog, "show", true);
  }

  private getRadiosSliderModel(radiosLength: number, times: string[]): number {
    if (isAfter(new Date(), new Date(this.job.endOn))) return radiosLength;

    let model = 0;
    let timeDiff = null;
    for (let idx = 0; idx < times.length; idx++) {
      if (times[idx]) {
        const diff = Math.abs(
          differenceInSeconds(new Date(), new Date(times[idx]))
        );
        if (timeDiff === null || diff < timeDiff) {
          timeDiff = diff;
          model = idx;
        } else break;
      }
    }
    return model;
  }
}
