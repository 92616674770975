import { ApiService } from "../api.service";
import { Equipment } from "@/models/entities/equipment.interface";

const CONTROLLER = "equipments";

class EquipmentService extends ApiService {
  getEquipmentsAnswersByCalendarJobId(calendarJobId: number) {
    return this.getByID<number, Equipment[]>(CONTROLLER, calendarJobId);
  }
}

export const equipment = new EquipmentService();
